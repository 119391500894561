import { $themeBreakpoints } from '@themeConfig'
import jwt from '@/auth/jwt/sclUseJwt'
import router from '@/router'
import swal from 'sweetalert2'

function failedMessage(message) {
  swal.fire({
    title: 'Error',
    text: message,
    icon: 'error',
    customClass: {
      confirmButton: 'btn btn-primary',
    },
    buttonsStyling: false,
  })
}

function warningMessage(message) {
  swal.fire({
    title: 'Warning',
    text: message,
    icon: 'warning',
    customClass: {
      confirmButton: 'btn btn-warning',
    },
    buttonsStyling: false,
  })
}

function setTokenTimeout(vuexContext, doAccessToken) {
  let timeoutValue = 0
  if (doAccessToken) {
    timeoutValue = vuexContext.state.accessExpiry
  } else {
    timeoutValue = vuexContext.state.refreshExpiry
  }

  setTimeout(() => {
    const refreshToken = jwt.getRefreshToken()
    const params = JSON.stringify({ refresh: refreshToken, user: vuexContext.state.username })
    jwt.axiosIns.post('/tokenupdate/', params)
      .then(response => {
        jwt.setToken(response.data.access)
        jwt.setRefreshToken(response.data.refresh)
        vuexContext.commit('setAccessExpiry', response.data.accessexp)
        vuexContext.commit('setRefreshExpiry', response.data.refreshexp)
        setTokenTimeout(vuexContext, doAccessToken)
      })
      .catch(e => {
        console.error(e)
        warningMessage('Your session has timed out')
        vuexContext.commit('clearToken')
        router.push('/login')
      })
  }, timeoutValue * 1000)
}

function addLineToAddress(state, line) {
  if (line === undefined) return

  state.currentApplicationDetails.address += line
  state.currentApplicationDetails.address += '\n'
}

function updateAddress(state, line1, line2, city) {
  if (state === undefined) {
    return
  }

  state.currentApplicationDetails.address = ''
  if (line1 !== null && line1 !== undefined) {
    addLineToAddress(state, line1)
  } else {
    addLineToAddress(state, state.currentApplicationDetails.addressLine1)
  }

  if (line2 !== null && line2 !== undefined) {
    addLineToAddress(state, line2)
  } else {
    addLineToAddress(state, state.currentApplicationDetails.addressLine2)
  }

  if (city !== null && city !== undefined) {
    addLineToAddress(state, city)
  } else {
    addLineToAddress(state, state.currentApplicationDetails.city)
  }
}

function CreateOperativeDetails(cscscard, workpermit, availability, tradename, vatreg, vatno, sclinv, scltax, sageId, clientId, feeOverride, notes) {
  this.cscscard = cscscard
  this.workpermit = workpermit
  this.availability = availability
  this.tradename = tradename
  this.vatreg = vatreg
  this.vatno = vatno
  this.sclinv = sclinv
  this.scltax = scltax
  this.sage_id = sageId
  this.client_ent_id = clientId
  this.feeoverride = feeOverride
  this.notes = notes
}

function CreateCISDetails(cisreg, utr, taxrate) {
  this.cisreg = cisreg
  this.utr = utr
  this.taxrate = taxrate
}

function CreateTradeDetails(tradeId) {
  this.trade_id_id = tradeId
}

function CreateVendorDetails() {
}

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    applicationValidated: null,
    shallShowOverlay: false,
    loggedIn: false,
    accessExpiry: 0,
    refreshExpiry: 0,
    username: '',
    trades: [],
    currentOperativeDetails: {},
    currentApplicationDetails: {
      ent_id: '-1',
      title: '',
      firstname: '',
      lastname: '',
      dob: 'new',
      active: false,
      ninumber: '',
      nationality: '',
      address: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      postcode: '',
      county: '',
      country: '',
      email: '',
      mobphone: '',
      homephone: '',
      name: '',
      updated: '',
      dayRate: '',
      comp_reg_no: '',
      comptype: 'soletrader',
      trade: '',
      trade_id: -1,
      trade_rate: '0',
      bankinfo_auth: false,
      terms_auth: false,
      ent_type: 'APPLICANT',
      sage_exp_enable: false,
      operativedetails: [new CreateOperativeDetails(false, '', 'new', '', false, '', false, false, '', -1, 0, '')],
      cisdetails: [new CreateCISDetails(false, '', '')],
      tradedetails: [new CreateTradeDetails(-1)],
      vendordetails: [new CreateVendorDetails('')],
    },
  },
  getters: {

    getLoggedIn(state) {
      return state.loggedIn
    },

    getCurrentOperative(state) {
      return state.currentOperativeDetails
    },

    getCurrentApplication(state) {
      return state.currentApplicationDetails
    },

    getApplicationValidation(state) {
      return state.applicationValidated
    },

    getTrades(state) {
      return state.trades
    },

    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    setLoggedIn(state) {
      state.loggedIn = jwt.getToken() !== null
    },

    clearToken(state) {
      state.loggedIn = false
      jwt.setToken('')
      jwt.setRefreshToken('')
      state.username = ''
      state.accessExpiry = 0
      state.refreshExpiry = 0
    },

    setUsername(state, value) {
      state.username = value
    },
    setAccessExpiry(state, value) {
      state.accessExpiry = value
    },

    setRefreshExpiry(state, value) {
      state.refreshExpiry = value
    },

    getCurrentOperative(state, opDetails) {
      state.currentOperativeDetails = opDetails
    },

    resetCurrentOperative(state) {
      state.currentOperativeDetails = {}
    },

    resetApplicationValidation(state) {
      state.applicationValidated = null
    },

    startApplicationValidation(state) {
      state.applicationValidated = true
    },

    failApplicationValidation(state) {
      state.applicationValidated = false
    },

    resetCurrentApplication(state) {
      state.currentApplicationDetails.ent_id = '-1'
      state.currentApplicationDetails.title = ''
      state.currentApplicationDetails.firstname = ''
      state.currentApplicationDetails.lastname = ''
      state.currentApplicationDetails.dob = 'new'
      state.currentApplicationDetails.active = false
      state.currentApplicationDetails.ninumber = ''
      state.currentApplicationDetails.nationality = ''
      state.currentApplicationDetails.address = ''
      state.currentApplicationDetails.addressLine1 = ''
      state.currentApplicationDetails.addressLine2 = ''
      state.currentApplicationDetails.city = ''
      state.currentApplicationDetails.postcode = ''
      state.currentApplicationDetails.county = ''
      state.currentApplicationDetails.country = ''
      state.currentApplicationDetails.email = ''
      state.currentApplicationDetails.mobphone = ''
      state.currentApplicationDetails.homephone = ''
      state.currentApplicationDetails.name = ''
      state.currentApplicationDetails.updated = ''
      state.currentApplicationDetails.dayRate = ''
      state.currentApplicationDetails.comp_reg_no = ''
      state.currentApplicationDetails.comptype = 'soletrader'
      state.currentApplicationDetails.trade = ''
      state.currentApplicationDetails.trade_id = -1
      state.currentApplicationDetails.trade_rate = '0'
      state.currentApplicationDetails.bankinfo_auth = false
      state.currentApplicationDetails.terms_auth = false
      state.currentApplicationDetails.ent_type = 'APPLICANT'
      state.currentApplicationDetails.sage_exp_enable = false
      state.currentApplicationDetails.operativedetails = []
      state.currentApplicationDetails.operativedetails.push(new CreateOperativeDetails(false, '', 'new', '', false, '', false, false, '', -1, 0, ''))
      state.currentApplicationDetails.cisdetails = []
      state.currentApplicationDetails.cisdetails.push(new CreateCISDetails(false, '', '0'))
      state.currentApplicationDetails.tradedetails = []
      state.currentApplicationDetails.tradedetails.push(new CreateTradeDetails(-1))
      state.currentApplicationDetails.vendordetails = []
      state.currentApplicationDetails.vendordetails.push(new CreateVendorDetails(''))
    },

    resetTrades(state) {
      state.trades = []
    },

    getTrades(state, trades) {
      state.trades = trades
    },

    setApplicationTitle(state, title) {
      state.currentApplicationDetails.title = title
    },
    setApplicationFirstname(state, firstname) {
      state.currentApplicationDetails.firstname = firstname
    },
    setApplicationLastname(state, lastname) {
      state.currentApplicationDetails.lastname = lastname
    },
    setApplicationDateOfBirth(state, dob) {
      state.currentApplicationDetails.dob = dob
    },
    setApplicationNINumber(state, ninumber) {
      state.currentApplicationDetails.ninumber = ninumber
    },
    setApplicationNationality(state, nationality) {
      state.currentApplicationDetails.nationality = nationality
    },
    setApplicationCSCS(state, cscs) {
      state.currentApplicationDetails.operativedetails[0].cscscard = cscs
    },
    setApplicationWorkPermit(state, wp) {
      state.currentApplicationDetails.operativedetails[0].workpermit = wp
    },

    setApplicationEmail(state, email) {
      state.currentApplicationDetails.email = email
    },
    setApplicationMobilePhone(state, mobphone) {
      state.currentApplicationDetails.mobphone = mobphone
    },
    setApplicationHomePhone(state, homephone) {
      state.currentApplicationDetails.homephone = homephone
    },

    setApplicationAddressLine1(state, address) {
      state.currentApplicationDetails.addressLine1 = address
      updateAddress(state, address, null, null)
    },

    setApplicationAddressLine2(state, address) {
      state.currentApplicationDetails.addressLine2 = address
      updateAddress(state, null, address, null)
    },

    setApplicationCity(state, city) {
      state.currentApplicationDetails.city = city
      updateAddress(state, null, null, city)
    },

    setApplicationPostcode(state, postcode) {
      state.currentApplicationDetails.postcode = postcode
    },

    setApplicationTradeName(state, name) {
      state.currentApplicationDetails.operativedetails[0].tradename = name
    },
    setApplicationTradeId(state, tradeId) {
      state.currentApplicationDetails.trade_id = tradeId
    },
    setApplicationAvailability(state, availabilityDate) {
      state.currentApplicationDetails.operativedetails[0].availability = availabilityDate
    },
    setApplicationVatNo(state, vat) {
      state.currentApplicationDetails.operativedetails[0].vatno = vat
    },
    setApplicationCompanyRegNo(state, regNo) {
      state.currentApplicationDetails.comp_reg_no = regNo
    },
    setApplicationUtr(state, utr) {
      state.currentApplicationDetails.cisdetails[0].utr = utr
    },
    setApplicationCompanyType(state, companyType) {
      state.currentApplicationDetails.comptype = companyType
    },
    setApplicationVatReg(state, vatReg) {
      state.currentApplicationDetails.operativedetails[0].vatreg = vatReg
    },
    setApplicationBankInfoAuth(state, auth) {
      state.currentApplicationDetails.bankinfo_auth = auth
    },
    setApplicationTermsAuth(state, auth) {
      state.currentApplicationDetails.terms_auth = auth
    },

    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
  actions: {

    getCurrentOperative(vuexContext, context) {
      vuexContext.commit('resetCurrentOperative', context)
      jwt.axiosIns.get(`/portal/${context.email}/`)
        .then(response => {
          vuexContext.commit('getCurrentOperative', JSON.parse(JSON.stringify(response.data)))
          router.push('/')
        })
        .catch(e => {
          if (e.response.status === 404) {
            failedMessage('Email not recognized, please try again or contact us on 01932596090')
          }
          console.error(e.response)
        })
    },

    resetCurrentOperative(vuexContext, context) {
      vuexContext.commit('resetCurrentOperative', context)
    },

    getTrades(vuexContext) {
      vuexContext.commit('resetTrades')
      jwt.axiosIns.get('/apptrades/')
        .then(response => {
          vuexContext.commit('getTrades', response.data)
        })
        .catch(e => {
          console.log(e.data)
          console.error(e)
        })
    },

    resetCurrentApplication(vuexContext, context) {
      vuexContext.commit('resetCurrentApplication', context)
    },

    logoutAndReset(vuexContext, context) {
      vuexContext.commit('resetCurrentOperative', context)
      // jwt.axiosIns.post('/logout/')
      //   .then(() => {
      //   })
      //   .catch(e => {
      //     console.error(e)
      //     vuexContext.commit('clearToken')
      //     router.push('/login')
      //   })

      vuexContext.commit('clearToken')
      router.push('/login')
    },

    setAccessTokenTimer(vuexContext) {
      setTokenTimeout(vuexContext, true)
    },

    setRefreshTokenTimer(vuexContext) {
      setTokenTimeout(vuexContext, false)
    },

    setLogoutTimer(vuexContext, duration) {
      setTimeout(() => {
        vuexContext.commit('resetCurrentOperative', '')
        jwt.axiosIns.post('/logout/')
          .then(() => {
          })
          .catch(e => {
            console.error(e)
            warningMessage('Your session has timed out')
            vuexContext.commit('clearToken')
            router.push('/login')
          })

        warningMessage('Your session has timed out')
        vuexContext.commit('clearToken')
        router.push('/login')
      }, duration)
    },
  },
}
