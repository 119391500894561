import Vue from 'vue'
import VueRouter from 'vue-router'
import jwt from '@/auth/jwt/sclUseJwt'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'profile',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Profile',
        requiresLogin: true,
        breadcrumb: [
          {
            text: 'Profile',
            active: true,
          },
        ],
      },
    },
    {
      path: '/remittances',
      name: 'remittances',
      component: () => import('@/views/Remittances.vue'),
      meta: {
        pageTitle: 'Remittances',
        requiresLogin: true,
        breadcrumb: [
          {
            text: 'Remittances',
            active: true,
          },
        ],
      },
    },
    {
      path: '/invoices',
      name: 'invoices',
      component: () => import('@/views/Invoices.vue'),
      meta: {
        pageTitle: 'Invoices',
        requiresLogin: true,
        breadcrumb: [
          {
            text: 'Invoices',
            active: true,
          },
        ],
      },
    },
    {
      path: '/application',
      name: 'application',
      component: () => import('@/views/Application.vue'),
      meta: {
        pageTitle: 'Application',
        requiresLogin: false,
        layout: 'full',
      },
    },
    {
      path: '/resetpassword',
      name: 'resetpassword',
      component: () => import('@/views/ResetPassword.vue'),
      meta: {
        pageTitle: 'Reset Password',
        requiresLogin: false,
        breadcrumb: [
          {
            text: 'Reset Password',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        requiresLogin: false,
        layout: 'full',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/Logout.vue'),
      meta: {
        requiresLogin: false,
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        requiresLogin: false,
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.name === 'login') {
    next()
    return
  }
  if (to.matched.some(record => record.meta.requiresLogin) && jwt !== undefined) {
    if (jwt.getToken() !== null && jwt.getToken().length) {
      next()
    } else {
      next({
        name: 'login',
      })
    }
  } else if (to.name === 'resetpassword' || to.name === 'application') {
    next()
  } else {
    next({
      name: 'login',
    })
  }
})

export default router
